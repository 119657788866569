import React from 'react';

import { useApi, useCache } from '../../common/minifield';

const useAccountCache = ({ id, namespace }) => {
	const api = useApi();
	const cache = useCache(id ?? namespace + '/accounts');
	const [accounts, setAccounts] = React.useState();
	
	React.useEffect(() => {
		(async () => {
			if (namespace) {
				setAccounts(cache() ?? cache(await api.get('/admin/account/list', { namespace })));
			}
		})();
	}, [api, namespace, cache]);
	
	React.useEffect(() => {
		if (accounts) {
			accounts.forEach(account => {
				account.name = account.name.trim();
			});
		}
	}, [accounts]);
	
	return accounts;
};
export { useAccountCache };