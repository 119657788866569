import React from 'react';
import RemoteContentProvider from './content-provider';
import { useNotification } from '../tools/notifications';

const ApiContext = React.createContext(RemoteContentProvider('http://localhost:8080/api/v1'));
export { ApiContext };

const Api = ({ apiUrl, apiKey, children }) => {
	const notification = useNotification({ type: 'banner' });
	
	const createContext = React.useCallback(() => {
		return RemoteContentProvider(apiUrl, {
			onError: (error) => {
				notification.warning('**Oops!** Sorry, we cannot connect to our server. Please try again later.');
			},
			headers: {
				'X-MINIFIELD-API': apiKey
			}
		});
	}, [apiUrl, apiKey, notification]);

	return (
		<div>
			<ApiContext.Provider value={ createContext() }>
				{ children }
			</ApiContext.Provider>
		</div>
	);
};
export { Api };
