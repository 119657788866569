import React from 'react';

import { useApi, useCache } from '../../common/minifield';
import { useAccountCache } from '../account/account-cache';

const useDataCache = ({ id, namespace, type, pagination = {}, filter = {} }) => {
	const api = useApi();
	const cache = useCache(id ?? namespace + '/data/' + type);
	const accounts = useAccountCache({ id: namespace + '/accounts#all', namespace });
	const [data, setData] = React.useState();

	React.useEffect(() => {
		(async () => {
			if (namespace && type) {
				const result = cache() ?? cache(await api.get('/admin/data/list', {
					namespace, type, ...pagination, ...filter
				}, (error) => setData()));
				if (Array.isArray(accounts) && Array.isArray(result)) {
					result.forEach(item => {
						if ('badge' in item) {
							item.account = accounts.find(account => account.badge === item.badge);
						}
					});
				}
				setData(result);
			}
		})();
	}, [api, accounts, cache, filter, namespace, pagination, type]);
	
	return data;
};
export { useDataCache };